.confirm-account-container{
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 1em;
    background-color: #F0F1F9;

    .nav-bar-page{
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E6E8FF;
        background-color: white;
        margin: 0 0 2em 0;
        

        img{
            height: 2.1em;
            margin: 0 0 0 4em;
        }
    }

    .confirm-account-message{
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;

        img{
            width: 30em;
            margin: 0 0 2em 0;
        }

        p{
            margin: 0;
        }

        p:nth-child(2){
            font-size: 1.2em;
            font-weight: 600;
            margin: 0 0 1em 0;
        }

        p:nth-child(4){
            margin: 1em 0 0 0;
        }

        button{
            margin: 1em 0 0 0;
            font-weight: 600;
            font-size: 0.9em;
            background-color: #EA6F2E !important;
            box-shadow: none;
        }
    }

    .MuiCircularProgress-root{
        width: 1.1em !important;
        height: 1.1em !important;
    }
}