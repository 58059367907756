@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  width: 80%;
  margin: 0.5em 0 0.5em 0;
  border-radius: 4px;
  border: none;
  background-color: #F0F1F9;
  height: 3em;
  padding: 0 0 0 1em;
  font-family: 'Work Sans', sans-serif;
  font-size: 0.96em;
}

.pointer {
  cursor: pointer;
}

.MuiButton-containedPrimary { 
    background-color: #1D7AFF !important;
    font-weight: bold;
    box-shadow: none;
}

.MuiButton-containedPrimary:hover { 
  background-color: #101010 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #101010 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #101010 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
