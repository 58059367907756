.kor-title-create{
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    padding: 1.3em 4em;
    background-color: #fbfbfd;
    align-items: center;
    color: #2a3158;

    p{
        font-size: 1.8em;
        font-weight: 600;
        margin: 0;
    }

    .name-api{
        color: #1D7AFF;
    }

    button{
        width: 17em;
        height: 3em;
        background-color: #EA6F2E !important;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        box-shadow: none;
        color: white !important;
        text-transform: capitalize;

        img{
            margin: 0 0.5em 0 0;
        }
    }

    button:hover{
        background-color: #EA6F2E !important;
    }

    .button-show-all{
        width: auto;
    }

}


.blank-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;

    p{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.4em;

    }

    button{
        width: 17em;
        height: 3em;
        background-color: #EA6F2E !important;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        box-shadow: none;
        color: white !important;

        img{
            margin: 0 0.5em 0 0;
        }
    }

    button:hover{
        background-color: #EA6F2E !important;
    }

    .button-show-all{
        width: auto;
    }
}


.sub-title{
    font-size: 1em;
    display: flex;
    margin: 0 0 0 4em;
    font-weight: 600;
    color: #2a3158;

    p{
        font-size: 1.3em;
        margin: 1em 0 1em 0;
    }
}

.error-alert{
    margin: 0 0 1.5em 0;
    font-size: 0.8em !important;
}


$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-phone) {
    .kor-title-create{
        padding: 1.5em;

        p{
            font-size: 1.3em;
        }

        button{
            width: 12em;
            font-size: 0.7em;

            img{
                width: 1em;
                height: 1em;
                margin: 0 0.3em 0 0;
            }
        }
    }

    .sub-title{
        padding: 0 1.5em;
        font-size: 0.9em;
        margin: 0;
    }

    .blank-screen{
        iframe{
            width: 100%;
            height: 15em;
        }

        p{
            font-size: 1em;
        }
    }

    

    

    .container-apis{
        margin: 0 1.5em !important;



        .container-item{
            width: 85% !important;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start !important;

            .name-info{
                width: 100% !important;

                .icon-api-item{
                    width: 2.5em;
                    height: 2.5em;

                    img{
                        width: 1.8em;
                    }
                }

                .api-name-info{
                    font-size: 0.9em;
                }
            }

            .endpoints-info{
                width: 100% !important;
                margin: 1em 0 0 0;
                flex-direction: column-reverse !important;
                align-items: flex-start !important;
                font-size: 0.9em;

                div{
                    margin: 1em 0 1em 0 !important;
                    width: 93% !important;
                }

                p{
                    width: 100%;
                }
            }

            .view-details-button{
                width: 100% !important;
                justify-content: flex-start !important;
                margin: 0.5em 0 0 0;

                button{
                    width: 50% !important;
                    font-size: 0.8em !important;

                    img{
                        margin: 0 0.5em 0 0 !important;
                    }
                }
            }
        }
    }
}

.loader-apis{
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 0.9em;

    .MuiCircularProgress-root{
        width: 15px !important;
        height: 15px !important;
        margin: 0 0.5em 0 0;
    }
}

@media only screen and (min-width: 768px) {
    .content-modal-warning{
        width: 40% !important;
    }
}

@media only screen and (min-width: 1200px) {
    .content-modal-warning{
     width: 30% !important;
 }  
}