.text-switch {
    display: flex;
}

.loading-mode{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    text-align: left;
    margin: 2em 0 1em 0em;
    background: #FFFFFF;
    padding: 8px 14px;
    border: 0.5px solid #F0F1F9;
    box-sizing: border-box;
    border-radius: 4px;

    p{
        margin: 0;
        text-align: left;
        font-size: 0.9em;
    }

    .progress-bar{
        margin: 0 0.5em 0 0;
        width: 100%;
    }

    .text-progress{
        font-weight: 500;
        display: flex;
        width: 100%;
    }

    .css-r40f8v-MuiTypography-root{
        font-family: 'Work Sans', sans-serif !important;
        width: 10%;
        font-weight: 600;
    }

    .css-eglki6-MuiLinearProgress-root{
        background-color: rgba(29, 122, 255, 0.15);
        height: 8px;
        border-radius: 4px;
        margin: 0 0 0 0.2em;
    }

    .MuiLinearProgress-bar{
        background-color: #1D7AFF;
        border-radius: 4px 0 0 0;
    }

    .css-pazuet{
        width: 1em
    }

    .MuiCircularProgress-root{
        width: 1.1em !important;
        height: 1.1em !important;
        margin: 0 0.5em 0 0;
    }
}

.loading-create-connection{
    width: 93%;
}

.text-switch .flex-ctn {
    display: flex;
}

.icon-ctn {
    background: #D2EAFE;
    border-radius: 50%;
    width: 34px;
    text-align: center;
    
    margin: 0 1em 0 0;
}


.info-text {
    color: #2A3158;
    text-align: left;
    font-weight: 500;
    margin-bottom: 0;
}

.small-text {
    color: #A2ADBD;
    text-align: left;
    font-size: 12px;
}

.icon-warning {
    font-size: 25px;
    padding: 2px 0;
    color: #4E99D1;
}


.type-ctn-1{
    background: #FFFFFF;
    padding: 8px 14px;
    border: 0.5px solid #F0F1F9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 2em 0 0 0;

    .text-type {
        color: #2A3158;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
    }

    img{
        margin: 0 1.4em 0 0;
        width: 2em;
    }

    .status-connection{
        padding: 1em 1em;
        display: flex;
        align-items: center;
        text-align: left;
    }


    h4{
        margin: 0;
        
    }

    .connection-message {
        p{
         margin: 0;
         color: #A2ADBD;
        }
    }

    .title-connect{
        color: #2A3158 !important;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.type-ctn {
    background: #FFFFFF;
    padding: 8px 14px;
    border: 0.5px solid #F0F1F9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px 0;


    .text-type {
        color: #2A3158;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
    }

    img{
        margin: 0 1.4em 0 0;
        width: 2em;
    }

    .status-connection{
        padding: 1em 1em;
        display: flex;
        align-items: center;
        text-align: left;
    }


    h4{
        margin: 0;
        
    }

    .connection-message {
        p{
         margin: 0;
         color: #A2ADBD;
        }
    }

    .title-connect{
        color: #2A3158 !important;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.warning-additional-ctn{
    display: flex;
    align-items: flex-start;
    padding: 2em;
    
    .warning-icon-additional{
        font-size: 1.4em;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        background-color: #D2EAFE;
        color: #4E99D1;
        border-radius: 50%;
        margin: 0 1em 0 0;
    }



    .info-additional{
        width: 90%;
        text-align: left;
        
        a{
            color: #1D7AFF;
            text-decoration: none;
        }
        p:nth-child(1){
            font-weight: 600;
            font-size: 1.2em;
        }
        span{
           font-weight: 600;
         }

         img{
             margin: 1em 0 1em 0;
             width: 23em;
         }
    }

    p{
        margin: 0;
    }
}

.badge-status-connection{
    display: flex;
    margin: 1em 0 1em 0;
    background-color: rgba(190, 255, 224, 0.50);
    padding: 0.8em 1.5em 0.8em 1em;
    align-items: center;

    img{
        margin: 0 0.5em 0 0;
    }

    p{
        font-size: 0.9em;
        color: #1FC173;
        font-weight: 600;
        border-radius: 4px;
        margin: 0;
    }
}


.info-base-url-1{
    text-align: left;
    margin: 0em 0 1em 0;
    width: 95%;
    font-size: 0.9em;

    span{
        font-weight: 600;
    }
}

.container-snippets{

    padding: 2em;
    
}

    .single-url-type{
        margin: 1em 0 0 0;
    }

    .secure-base-url-ctn{
        text-align: left;
        display: flex; 
        align-items: center;
        width: 100%;
        justify-content: flex-start;

        p{
            margin: 0;
            font-weight: 600;
        }


    }

    .url-secure-api{
        background-color: #F0F1F9;
        width: 80%;
        padding: 0.2em 0;
        border-radius: 30px;
        font-weight: 600;
        font-size: 1.1em;
        color: #1D7AFF;
        margin: 1.5em 0 1.5em 0;
    }

    .div-opacity-details{
        width: 1em;
        height: 1em;
        background-color:rgba(29, 122, 255, 0.5);
        border-radius: 50%;
        margin: 0 1em 0 0;
    }

    .base-url-ctn{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em 2em 1em 2em;

        .info-base-url{
            width: 100%;
            margin: 0 0 1em 0;
            text-align: left;

            a{
                color: #1D7AFF;
                text-decoration: none;
            }
        }

    }

    .headers-snippet{
        width: 100%;
        display: flex;
    }

    .documentation-url{
        width: 100%;
        border-top: 1px solid #F0F1F9;
        color: #A2ADBD;
        text-align: left;
        display: flex;
        justify-content: space-between;

        p:nth-child(2){
            color: #1D7AFF;
            cursor: pointer;
        }

        p{
            margin: 1em 0 1em 0;
        }
    }

    .code-example{
        color: #A2ADBD;
        width: 100%;
        text-align: left;
        font-size: 0.9em;
        margin: -0.5em 0 0 0;

        a{
            text-decoration: none;
            color: #1D7AFF;
        }
    }

    .bar-button-ctn{
        display: flex;
        justify-content: space-between;
        align-items: center;

        button{
            height: 2.3em;
            width: 15em;
            text-transform: capitalize;
            font-weight: 600;
            background-color: #1D7AFF;
            color: white;
        }

        button:hover{
            background-color: #1D7AFF;
            color: white;
        }
    }


.security-level-ctn{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;

    .title-security-level{
        font-weight: 600;
    }

    img{
        width: 80%;
    }

    .level-bar{
        background-color: #D2EAFE;
        height: 1.3em;
        border-radius: 30px;
        width: 75%;


        .background-level{
            position: absolute;
        }

        .progress-level{
            width: 70%;
            height: 1.3em;
            background-color: #4E99D1;
            border-radius: 30px;
        }

        .progress-level-security{
            width: 90%;
            height: 1.3em;
            background-color: #4E99D1;
            border-radius: 30px;
        }
    }

    .level-bar-security{
        background-color: rgba(31, 193, 115, 0.15);
        height: 1.3em;
        border-radius: 30px;
        width: 100%;

        .background-level{
            position: absolute;
        }
        
        .progress-level-security{
            width: 80%;
            height: 1.3em;
            background-color: #1FC173;
            border-radius: 30px;
            position: relative;
            z-index: 3;
        }

        button{
            margin: 0 0 0 1em;
        }
    }



    .more-info{
        margin: 1em 0 2em 0;
        font-size: 0.9em;
        color: #1D7AFF;

        a{
            color: #1D7AFF;
            text-decoration: none; 
        }
    }
}

.details-ctn::-webkit-scrollbar {
    display: none;
  }

  .details-ctn {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


.details-ctn {
    width: 96%;
    background-color: #fbfbfd;
    padding: 0 0em;
    height: 100vh;
    overflow: scroll;

    .type-flex {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        
        .status-ctn {
            padding: 12px;
        }


    }

    .tabs-ctn {
        margin: 3em 0 0 0;
        display: flex;
        width: 100%;
        background-color: #fbfbfd;
        border-bottom: solid 4px #F0F1F9;
        padding: 0.05em 0;

        p{
            margin: 0;
        }

        .p-tabs {
            color: #A2ADBD;
            margin-right: 60px;
            font-weight: 500;
            cursor: pointer;
           
        }

        .p-tabs-active {
            color: #2A3158;
            margin-right: 60px;
            font-weight: 500;
            border-bottom: 4px solid #1D7AFF;
            padding-bottom: 10px;
            margin-bottom: 0;
            cursor: pointer;
            margin: 0 60px -0.2em 0;
        }
    }
}

.details-ctn-1{
    width: 95%;
    padding: 0 4em 0 2em;
}

.tooltip-warning-rate{
    display: flex;
    align-items: flex-start;
    padding: 1em 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    
    img{
        width: 1.5em;
        margin: 0 0.5em 0 0;
        cursor: pointer;
    }
    p{
        margin: 0;
    }
}


.top-bar-connection-ctn{
    width: 100%;
    background-color: white;
    padding: 1em 0 1em 0;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;

    .rate-limit-ctn{
        background-color: rgba(29, 122, 255, 0.10);
        width: auto;
        margin: 0 0 0 2em;
        display: flex;
        border: 2px solid #D2EAFE;
        border-radius: 4px;
        align-items: center;
        padding: 0.5em 0.5em 0 0.5em;
        font-size: 0.9em;
        justify-content: space-between;

        input{
            width: 4em;
            height: 2em;
            background-color: white;
            border: 1px solid #F0F1F9;
            border-radius: 4px;
            margin: 0 0.2em 0  0;
        }

        p{
            font-weight: 600;
            margin: 0;
            text-align: left;
        }

        button{
            margin: 0 0.5em 0 1em;
            text-transform: capitalize;
            font-weight: 600;
            height: 2.3em;
        }



        button:hover{
            background-color: #1D7AFF !important;
        }

        .input-ctn-rate{
            display: flex;
            align-items: center;
            margin: 0.3em 0 0.3em 0;
            
            p{
                font-weight: 400 !important;
                margin: 0 0.5em 0 0;
            }
        }
    }
    
    .Mui-disabled{
         color: rgb(214, 213, 213) !important;
    }

    .connection-security-ctn{
        display: flex;

        
        .connection-mode-warning{
            display: flex;
            align-items: center;

            img{
                width: 1.3em;
                margin: -0.1em 1.5em 0 0;
            }
        }
        
        .connection-mode-ctn{
            background-color: rgba(240, 241, 249, 0.25);
            border: 1px solid #F0F1F9;
            display: flex;
            align-items: center;
            padding: 1em 1em 0 1em;
            margin: 0 1em 0 0;
            font-size: 0.9em  !important;
            border-radius: 4px;
            width: 20em;
            justify-content: space-between;


            p{
                margin: 0 0 -0.5em 0;
                text-align: left;
                font-weight: 600;
            }

            .MuiTypography-body1{
                font-size: 0.97em  !important; 
                font-family: 'Work Sans', sans-serif;
                line-height: 0;
                letter-spacing: 0;
                margin: 0 0 0 -0.5em;
            }

            .MuiRadio-colorSecondary.Mui-checked{
                color: #1D7AFF;
            }
        }

        .warning-ctn-conn{
            background-color: rgba(240, 197, 71, 0.15);
            border: 1px solid #FEE499;
        }

        .security-type-ctn{
            margin: 0 4em 0 0;
            width: 18em;
            background-color: rgba(190, 255, 224, 0.25);
            border: 1px solid #BEFFE0;
            border-radius: 4px;
            padding: 1em 1em 0 1em;
            display: flex;
            flex-direction: column;
            text-align: left;
            font-size: 0.9em;

            p{
                margin: 0;
            }

            p:nth-child(1){
                font-weight: 600;
            }
        }
    }
}


.rate-limit-opacity-0{
    opacity: 0;
}

.api-gateway-details-container{
    display: flex;
    width: 100%;

    .active-tab-m{
        color: #1D7AFF;
        font-weight: 600;
        font-size: 1.05em;
    }

    .snippet-code pre {
      width: 100%;
    }



    .menu-details{
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #f0f1f9;
        margin: 0em;
        height: 90vh;
        justify-content: space-between;

        .button-show-all-connections{
            button{
                background-color: #EA6F2E  !important;
                text-transform: none;
                font-weight: 600;
                margin: 0 0 2em 0;
            }

            img{
                margin: 0 0.5em 0 0;
            }
        }

        .sections-menu{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 1em 4em 0 0;

            .section-menu{
                width: 100%;
                display: flex;
                padding: 0 4em;
                align-items: center;
                cursor: pointer;

                img{
                    width: 1.5em;
                    height: 1.5em;
                    margin: 0 1em 0 0;
                }
            }

            .section-menu-active{
                background-color: rgba(29,122,255,.15);
            }

            .section-menu:hover{
                background-color: rgba(29,122,255,.15);
            }
        }

        .down-arrow{
            display: flex;
            align-items: center;
            margin: 0 0 0 0.5em;

            img{
                width: 1em;
                height: 1em;
            }
        }

        .kor-tree{
            font-weight: 600;
            margin: 3em 0 0 0;
            font-size: 1.2em;

            p{
                margin: 0 0em 0 0;
                text-align: left;
            }

            span:nth-child(2){
                color: #1D7AFF;
            }

            span:hover{
                color: #1D7AFF;
                cursor: pointer;
            }
        }

        .ready-to-use{
            font-size: 1em;
            background-color: rgba(31,193,115,.15);
            padding: 0 1.6em;
            border-radius: 4px;
            color: #1FC173;
            font-weight: 600;
            margin: 1em 0 0 0;

            p{
                font-size: 0.7em;
            }
        }

        .security-type-container{
            background-color: rgba(31,193,115,.15);
            display: flex;
            width: 100%;
            margin: 2em 0 0 0;
            align-items: center;
            padding: 1em 0 1em 0;
            border-radius: 4px;

            .security-type{
                margin: 0 0 0 1em;
                text-align: left;
                font-weight: 600;

                p:nth-child(1){
                    color: #A2ADBD;
                }
            }

            p{
                margin: 0;
            }

            img{
                width: 1.7em;
                height: 1.7em;
                margin: 0 0 0 1em;
            }
        }

        .connection-mode-container{
            width: 100%;
            margin: 2em 0 0 0;

            .connection-title{
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #F0F1F9;

                p{
                    margin: 0 0 0.5em 0;
                }

                p:nth-child(1){
                    font-weight: 600;
                    font-size: 1.1em;
                }

                a{
                    color: #1D7AFF;
                    text-decoration: none;
                }
            }

            .testing-prod-modes{
                display: flex;
                margin: 1em 0 0 0;
                font-size: 0.9em;
                
                .select-mode{
                    width: 50%;
                    border: 1px solid #F0F1F9;
                    cursor: pointer;
                }

                .left-testing{
                    border-radius: 30px 0 0 30px;
                }

                .right-prod{
                    border-radius: 0 30px 30px 0;
                }

                .active-select-mode{
                    background-color: rgba(29,122,255,.15);
                    color: #1D7AFF;
                    font-weight: 600;
                    border: none;
                   
                }
            }

            .warning-testing{
                display: flex;
                align-items: center;
                margin: 1em 0 0 0;
                background-color: rgba(240,197,71,.15);
                padding: 1em;
                border-radius: 4px;

                p{
                    font-size: 0.8em;
                    margin: 0;
                }

                img{
                    width: 1em;
                    height: 1em;
                    margin: 0 0.6em 0 0;
                }
            }
        }

        .allowed-domains-container{
            width: 100%;
            margin: 2em 0 0 0;

            .allowed-domains-title{
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #F0F1F9;

                p{
                    margin: 0 0 0.5em 0;
                }

                .icon-allowed{
                    display: flex;
                    width: 70%;

                    p{
                        font-weight: 600;
                        font-size: 1.1em;
                    }

                    img{
                        width: 1.2em;
                        margin: 0 0.5em 0.5em 0;
                    }
                }

                .see-all{
                    color: #1D7AFF;
                    cursor: pointer;
                }
            }

            .allowed-domains{
                p{
                    text-align: left;
                }
            }
        }

        .details-api-gateway-container{
            display: flex;
            align-items: center;
            margin: 2em 0 0 0;

            .title-api-gateway{
                font-weight: 600;
                font-size: 1.1em;
            }

            p{
                text-align: left;
                margin: 0;
            }

            .update-created-by{
                text-transform: uppercase;
                font-size: 0.85em;
                margin: 0.2em 0 0 0;
                font-weight: 600;
                letter-spacing: -0.5px;

                span{
                    color: #8186A5;
                    font-weight: normal;
                }
            }

            .icon-api-gateway{
                background-color: #1FC173;
                width: 2.3em;
                height: 2.3em;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0.5em 0 4em;

                img{
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }

        .rate-limit-container{
            width: 100%;
            margin: 1em 0 0 0;


            .rate-limit-count{
                margin: 1em 0 0em 0;
                text-align: left;
            }

            .rate-limit-title{
                width: 100%;
                display: flex;
                border-bottom: 1px solid #F0F1F9;
                justify-content: space-between;
                padding: 0 0 0.5em 0;

                p{
                    margin: 0 0 0em 0;
                    font-weight: 600;
                    font-size: 1.1em;
                }

                div{
                    display: flex;
                    align-items: center;
                }

                span{
                    color: #1D7AFF;
                }

                .circle-title{
                    width: 0.7em;
                    height: 0.7em;
                    background-color: #1D7AFF;
                    margin: 0 0.5em 0em 0;
                    opacity: 50%;
                    border-radius: 50%;
                }
            }
        }

    }

    .api-details{
        width: 100%;
        overflow: hidden;
        margin: 0 4em 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex;
    }
}

.security-type-badge{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .type-flex{
        width: 50%;
    }
}

.warning-connection{
    a{
        text-decoration: none;
        color: #1D7AFF;
    }
}

.snippet-code{
    display: flex;
    margin: 0 0 1em 0;

    pre{
        width: 100%;
        font-size: 0.9em;

    }
}

.allowed-modal{
    display: flex;
    align-items: center;
    text-align: left;

    p{
        width: 15%;
        font-size: 0.9em;
    }

    input{
        font-size: 0.9em;
    }

}


.input-modal-add{
    color: #1D7AFF !important;
    text-align: right;
    font-size: 0.9em;
    cursor: pointer;
}

.input-modal-add-button{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    button{
        width: 12em;
        height: 2.5em;
        font-weight: 600;
    }
}



.snippet-type{
    display: flex;
    border-bottom: 3px solid #F0F1F9;
    margin: 0em 0 2em 0;

    p{
        margin: 0 1em -0.09em 0;
        font-size: 0.9em;
        text-transform: uppercase;
        cursor: pointer;
        color: #A2ADBD;
        padding: 0 0 0.7em 0;
        width: 5em;
    }

    .active-tab{
        color: #1D7AFF;
        font-weight: 600;
        border-bottom: 3px solid #1D7AFF;
    }
}

.details-page-container{
    display: flex;
    flex-direction: column;
    text-align: left;

    .title-api{
        font-size: 1.5em;
        font-weight: 600;
    }

    .title-api-1{
        font-size: 1.07em;
        font-weight: 600;
    }

    .section-info{
        display: flex;
        flex-wrap: wrap;

        div{
            width: 50%;
            margin: 0.5em 0 0 0;

            p{
                margin: 0;

                span{
                    font-weight: 600;
                }
            }
        }
    }

    .endpoint-api{
        margin: 0em 0 0 0;
        text-align: center;
        font-size: 1.1em;
        border: 2px solid #f0f1f9;
        padding: 1em;
        
        p{
            margin: 0;
        }

        p:nth-child(1){
            font-weight: bold;
            color: #1D7AFF;
        }

        p:nth-child(2){
            font-weight: 400;
            font-size: 0.9em;
            margin: 0.3em 0 0 0;
        }

    }

    .environment {
        margin: 0em 0 0 0;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.1em;
        border: 2px solid #f0f1f9;
        padding: 1em;
        
        .MuiSwitch-colorSecondary.Mui-checked {
            color: #1d7aff;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #1d7affe0;
        }

        .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
            background-color: #000 !important;
        }

        .MuiSwitch-colorSecondary.Mui-disabled {
            color: #bdbdbd !important;
        }

        span.MuiSwitch-thumb {
            color: #1d7aff;
        }

        span.MuiSwitch-track {
            background-color: #1d7affe0;
        }
        
        span {
            font-weight: bold;
        }

        p{
            margin: 0;
        }

        p:nth-child(1){
            font-weight: bold;
            color: #1D7AFF;
        }

        p:nth-child(2){
            font-weight: 400;
            font-size: 0.9em;
            margin: 0.3em 0 0 0;
        }

    }

    .api-key-container{
        margin: 0 0 2em 0;
    }

    .input-target-api{
        display: flex;
        justify-content: space-between;
        align-items: center;

        button{
            height: 2.4em;
            width: 11em;
            background-color: #1D7AFF !important;
            border-color: #1D7AFF !important;
            color: white;
            font-weight: bold;
        }

        input{
            height: 2.5em;
        }
    }


    .header-name-api-details{
        display: flex;
        justify-content: space-between;

        .header-name-api-details_div{
            display: flex;
            width: 80%;

            p:nth-child(1){
                width: 20%;
                font-weight: 600;
            }

        }

        button{
            width: 12em;
            height: 2.5em;
            font-weight: 600;
            box-shadow: none;
            font-size: 0.8em;
            border-radius: 10px;
        }

        button:hover{
            background-color: #1D7AFF !important;
        }
    }


    .target-api-ctn{
        margin: 2em 0 0 0;
        padding: 1em 2em 0 2em;

        button{
            text-transform: capitalize;
            font-weight: 500;
            background-color: white !important;
            color: #1D7AFF;
            font-size: 0.9em;
        }

        button:hover{
            background-color: white !important;
            color: #1D7AFF;
            font-size: 0.9em;  
        }

        p{
            margin: 0 0 1.5em 0;
        }
    }

    .title-target-api-info{
        display: flex;
        margin: 0 0 2em 0;

        p{
            margin: 0;
            font-weight: 600;
            font-size: 1.1em;
        }

        div{
            width: 1em;
            height: 1em;
            background-color: #1D7AFF;
            opacity: 50%;
            border-radius: 50%;
            margin: 0 1em 0 0;
        }
    }

    .domains-ctn{
        padding: 2em;
    }

    .delete-connection-container{
        margin: 2em 0 0 0;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        button{
            background-color: #8186A5;
            color: white;
            width: 15em;
            font-family: 'Work Sans';
            font-weight: 400;
            text-transform: none;

            img{
                margin: 0 0.5em 0 0;
            }
        }
    }


    .section-domains{
        margin: 1.5em 0 0 0;

        .title-domains{
            display: flex;
            justify-content: space-between;


            p{
                font-size: 1.1em;
                font-weight: 600;
            }

            button{
                width: 17em;
                height: 3em;
                background-color: #1D7AFF !important;
                font-weight: 600;
                letter-spacing: 1px;
                font-size: 0.9em;
                display: flex;
                align-items: center;
                box-shadow: none;
                color: white !important;
                text-transform:initial;
                border-radius: 10px;

                img{
                    width: 1.7em;
                    margin: 0 0.2em 0 0;
                }
            }
        }



        table{
            width: 100%;
            border-collapse: collapse;
            margin: 3em 0 0 0;

            th{
                font-weight: 600;
                font-size: 0.9em;
                padding: 0.7em 0;
                text-align: left;
                color: #A2ADBD;
                border-bottom: 1px solid #F0F1F9;

            }

            td{
                padding: 0.5em 0;
                text-align: left;
                color: #2A3158;
            }
        }
    }
}

.snippets-section{
    display: flex;
    flex-direction: column;
    text-align: left;

    p{
        margin: 0;
    }

    .title-snippets{
        font-weight: 600;
        margin: 1em 0 1em 0;
    }


}

.title-snippets-1{
    font-weight: 600;
    margin: 1em 0 0em 0;
    width: 100%;
    text-align: left;
    font-size: 1.4em
}

.button-show-all{
    font-size: 1em;
    p{
        font-size: 1em;
    }
}

.copy-snippet-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1em 0 0 0;

    button{
        text-transform: capitalize;
        font-weight: 600;
        box-shadow: none;
    }
}

.details-api-gateway-container{
 .button-show-all-connections{
     display: none;
 }   
}

.details-api-gateway-container-1{
    display: flex;
    align-items: center;
}

.mobile-warning{
    display: none;
}


$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-phone) {
    .mobile-warning{
        display: flex;
    }

    .desktop-warning{
        display: none;
    }


    .kor-title-create{
        text-align: left !important;
        width: 90%;
        .button-show-all{
            width: 1.4em !important;
            font-size: 1.4em;
            height: 1.4em !important;

            img{
                width: 1.5em;
                margin: 0;
            }

            p{
                display: none;
            }
        }
    }

    .input-target-api{
        button{
            font-size: 0.7em;
            width: 10em !important;
        }

        input{
            font-size: 0.9em;
        }
    }
    .details-ctn{
        width: 100%;
    }
    .details-ctn-1{
        padding: 0;

        .badge-status-connection{
            width: 90%;
            margin: 1em 0 0 0;
        }

        .info-base-url-1{
            width: 100%;
            font-size: 0.8em;
            margin: 1em 0 0 0;
        }

        .base-url-ctn{
            padding: 1em 1em 0em 1em;
        }

        .secure-base-url-ctn p, .documentation-url{
            font-size: 0.9em;
        }

        .bar-button-ctn{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            button{
                margin: 1em 0 0 0;
            }

            .level-bar{
                width: 100%;
            }
        }

        .security-type-badge{
            flex-direction: column;
                .type-flex{
                    width: 100%;
                    margin: 0;

                    p{
                        text-align: left;
                    }
            
            } 
        }

        .warning-additional-ctn{
            flex-direction: column;
        }

        .warning-icon-additional{
            width: 1em;
            height: 1em;
            margin: 0 0 1em 0;
        }

        .security-level-ctn img{
            width: 100%;
        }

        .warning-additional-ctn p{
            font-size: 0.9em;
        }

        .warning-additional-ctn .info-additional img{
            margin: 1em 0 1em 0;
            width: 20em;
        }

        .title-snippets-1{
            font-size: 1.2em;
        }

      .info-additional p:nth-child(1){
          font-size: 1em;
      }

        .url-secure-api{
            width: 95%;
            font-size: 0.8em;
        }
    }

    .top-bar-connection-ctn{
        flex-direction: column;
        border: 1px solid #e9ecef;
        width: 99%;
        font-size: 0.9em;

        .connection-security-ctn{
            flex-direction: column;
        }

        .connection-mode-ctn{
            width: 85% !important;
            margin: 0 0 0.5em 1em !important;
            height: 4em;
        }

        .security-type-ctn{
            width: 85% !important;
            margin: 0 0 0.5em 1em !important;
            height: 4em;
        }
    }

    .api-gateway-details-container{
        flex-direction: column;

        .menu-details{
            width: 90%;
            flex-direction: row;
            margin: 0em 0em 0 1.5em ;
            align-items: center;
            height: auto;
            border: none;
            align-items: flex-start;
            padding: 0 0 1em 0;

            .details-api-gateway-container{
                margin: 1em 0 0 0;

                .icon-api-gateway{
                    width: 2em;
                    height: 2em;
                    margin: 0 0.5em 0 0;
                }

                .down-arrow{
                    margin: 0;
                }
            }

            .button-show-all-connections{
                display: none !important;
            }



            .section-menu{
                padding: 0.3em 0.3em !important;
                margin: 0 0.3em 0 0;
                font-size: 0.8em;
                
                img{
                    margin: 0 0.3em 0 0 !important;
                }
            }

            .details-api-gateway-container{
                display: flex;
                justify-content: space-between;
                .button-show-all-connections{
                    display: flex !important;
                     
                    button{
                        margin: 0;

                        img{
                            margin: 0;
                        }
                    }
                }   
            }

            .sections-menu{
                flex-direction: row;
            }

            .section-click{
                width: auto;
            }

            .title-menu{
                margin: 0;
                font-size: 1em;
            }

            p{
                margin: 0 1em 0 0 !important;
                padding: 0 !important;
                border-bottom: none !important;
            }

            .active-tab-m{
                font-size: 1em !important;
            }
        }

        .api-details{
            width: 90%;
            margin: 1em 0 0 1.5em;

            .title-api{
                font-size: 1.1em;
            }

            .endpoint-api{
                font-size: 1em;
            }

            .title-domains{
                font-size: 1em;
                
                button{
                    font-size: 0.7em;
                    width: 18em;
                }
            }

            table{
                margin: 1em 0 0 0;
                font-size: 0.9em;
            }

            .section-info{
                flex-direction: column;

                div{
                    width: 100%;
                    font-size: 0.9em;
                }
            }

            .header-name-api-details{
                font-size: 0.9em;


                button{
                    width: 10em;
                    margin: 0;
                    font-size: 0.73em;
                }
            }
        }
    }

    .snippet-type{
        font-size: 0.9em;
    }

    .snippets-section{
        font-size: 0.9em;
    }

    .header-name-api-details_div{
        width: 100% !important;
        font-size: 0.9em
    }

    .header-name-api-details{
        button{
            width: 8em;
        }
    }

    .MuiAlert-standardError{
        margin: 1em 0 0 0;
    }

    .loading-mode{
        width: 100%;
        font-size: 0.8em;
    }

    .rate-limit-ctn{
        width: 88% !important;
        margin: 0 0 0.5em 1em  !important;
    }


}



// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    .snippet-code {
        width: 300px;
    }

    .security-level-ctn{
        img{
            width: 100%; 
        }
    }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .snippet-code {
        width: 400px;
    }

    .security-level-ctn{
        img{
            width: 100%; 
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .snippet-code {
        width: 850px;
    }

    .security-level-ctn{
        img{
            width: 80%; 
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .snippet-code {
        width: 850px;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
    .snippet-code {
        width: 850px;
    }
}
