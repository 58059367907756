.app-container{
    width: 100%;
    height: 100vh;
    color: #2A3158;

    .app-header{
        font-size: 1em;
        height: 10%;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;

        .kor-connect{
            display: flex;
            align-items: flex-end;
            
            img{
                width: 8.5em;
            }
            
            p{
                margin: 0em 0 0 0.3em;
                font-size: 1.1em;
                color: #1D7AFF;
                font-weight: 600;
            }
        }

        img{
            width: 2em;
            height: 2em;
            margin: 0 0 0 4em;
        }

        div{
            display: flex;
            align-items: center;
            margin: 0 4em 0 0;
            color: #8186A5;
            height: 2.5em;


            button{
                color: #293158;
                margin: 0 0 0 1em;
                border: 1.5px solid #293158 !important;
                
                font-weight: bold;
                text-transform: capitalize !important;
                width: 9em;
                height: 100%;
            }

            .MuiButton-outlined{
                border: 1.5px solid #8186A5 !important;
            }
        }
    }
    
    .main-page{
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0em 0 0 0;

        .image-kor-main{
            width: 50%;
            height: 100%;
            background-color: #FAF8FD;
            display: flex;
            align-items: flex-end;

            img{
                width: 100%;
            }
        }

        .login-signup-form{
            padding: 15% 0 5% 0;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.registrer-form{
    padding: 0 4em 0 4em;
    font-size: 1em;

    h1{
        width: 100%;
        text-align: left;
        margin: 0;
        font-size: 2.3em;

        span{
            color: #1D7AFF;
        }
    }

    button{
        width: 9em;
        background-color: #1D7AFF !important;
        font-weight: bold;
        height: 3em;
    }

    .muted-text{
        margin: 0 0 2em 0;
        text-align: left;
    }
}

.muted-text-login{
    span{
        color: #1D7AFF;
    }
    
    text-align: left;
    margin: 0em  4em 0 4em;
}


.form-container{
    width: 100%;

    .input-container{
        display: flex;
        margin: 0 0 1em 0;
        flex-direction: column;
    }

    button{
        text-transform: capitalize;
    }

    form{
        width: 100%;
        display: flex;
        flex-direction: column;

        input{
            width: 97%;
            margin: 0.5em  0 0.5em 0;
            border-radius: 4px;
            border: none;
            background-color: #F0F1F9;
            height: 3em;
            padding: 0 0 0 1em;
            font-family: 'Work Sans', sans-serif;
            font-size: 1em;
        }

        input:focus{
            outline: none;
        }

        p{
            margin: 0 0 0.5em 0;
            text-align: left;
        }

        .input-container{
            p{
                font-weight: bold;
            }
        }
    }

}

.loader-account{
    display: flex;
    margin: 1em 0 1em 0;
    align-items: center;

    p{
        margin: 0 !important;
        font-weight: 400 !important;
        font-size: 1.1em !important;
    }
}

.error-login{
    color: #EB222E;
    width: 100%;
    text-align: left;
    margin: 0 0 1em 0;
}

.success-login {
    color: #00C851;
    width: 95%;
    text-align: left;
    margin: 0 0 2em 0;
    display: flex;
    margin: 0em 0 1em 0;
    background-color: rgba(190, 255, 224, 0.5);
    padding: 0.8em 1.5em 0.8em 1em;
    align-items: center;
    font-weight: 600 !important;

    p{
        margin: 0 0 0 0.5em !important;
    }
}

$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-phone) {
    .app-header{
        position: initial !important;
        background-color: #FAF8FD;
        margin: 0 !important;
        height: auto !important;
        padding: 1.5em 0;

        .kor-connect img{
            margin: 0 0 0 1.5em;
            width: 6.5em !important;
        }

        button{
            width: 8em !important;
            font-size: 0.8em;
            margin: 0 1.5em 0 0 !important;
        }

        div{
            margin: 0 !important;
        }

        p{
            font-size: 0.8em;
            display: none;
        }
    }

    .main-page{
        flex-direction: column;

        
        .image-kor-main{
            width: 100% !important;
            align-items: flex-end !important;
            height: auto !important;
        }

        .login-signup-form{
            width: 100% !important;
            padding: 0 !important;
            justify-content: center !important;

            .registrer-form{
                padding: 1.5em;

                .form-container form{
                    height: auto;
                    margin: 1em 0 0 0;
                }

                .error-login,
                .success-login{
                    margin: 0;
                    font-size: 0.9em;
                }

                .input-container{
                    margin: 1em 0 0.5em 0;
                    font-size: 0.9em
                }

                button{
                    font-size: 0.8em;
                }

                h1{
                    text-align: center;
                }

                .muted-text{
                    font-size: 0.9em;
                    text-align: center;
                }
            }

            .muted-text-login{
                font-size: 0.9em;
                text-align: center;
            }
        
        }
    }


}