.modal-container{
    background-color: rgba(42, 49, 88, 0.5);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;

    .modal-info-user{
        background-color: white;
        width: 50%;
        margin: 2em 0 0 0;
        padding: 2em;
        height: fit-content;
    }

    .user-close-modal{
        display: flex;
        margin: 0 0 2em 0;

        p{
            font-size: 1.4em;
            font-weight: 600;
            margin: 0;
        }
    }

    .subtitle-p{
        text-align: left;
        font-size: 0.9em;
    }

    .title-ctn {
      width: 170px;
      text-align: left;
    }

    .input-container{
        display: flex;
        align-items: center;
        font-size: 0.9em;

        p{
            margin: 0 2em 0 0;
        }

        input{
            width: 90%;
        }

        select::-ms-expand {
            display: none;
        }

        select{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 90%;
            margin: 0.5em 0 0.5em 0;
            border-radius: 4px;
            border: none;
            background-color: #F0F1F9;
            height: 3em;
            padding: 0 0 0 1em;
            font-family: 'Work Sans', sans-serif;
            font-size: 0.96em;
            background-image:
                linear-gradient(45deg, transparent 50%, gray 50%),
                linear-gradient(135deg, gray 50%, transparent 50%),
                linear-gradient(to right, #ccc, #ccc);
            background-position:
                calc(100% - 20px) calc(1em + 2px),
                calc(100% - 15px) calc(1em + 2px),
                calc(100% - 2.5em) 0.5em;
            background-size:
                5px 5px,
                5px 5px,
                1px 1.5em;
            background-repeat: no-repeat;
        }
        
    }

    .buttons-close{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 1em 0 0 0;   

        button:nth-child(1){
            color: #8186a5;
            margin: 0 .5em 0 0;
            border: 1.5px solid #8186a5!important;
            font-weight: 600;
            width: 10em;
            height: 3em;
            background-color: #fff!important;
            box-shadow: none;
        }

        button:nth-child(2){
            width: 10em;
            height: 3em;
            background-color: #ea6f2e!important;
            font-weight: 700;
            letter-spacing: 1px;
            font-size: .9em;
            display: flex;
            align-items: center;
            box-shadow: none;
        }
    }
}

$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-phone) {
    .modal-info-user{
        width: 100% !important;
        height: 80% !important;

        .input-container{
            flex-direction: column;
            .title-ctn{
                width: 100%;
            }

            select{
                width: 100%;
               
            }

            input{
                width: 98%;
            }
            p{
                margin: 0 !important;
                padding: 0  !important;
                width: 100%;
            }


        }

        button{
            font-size: 0.76em !important;
            width: 9em;
        }
    }
}