.kor-navbar{
    padding: 1.5em 4em;
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    border-bottom: 1px solid #e9ecef;

    .kor-connect{
        display: flex;
        align-items: flex-end;
        cursor: pointer;

        img{
            width: 7em;
        }
        p{
            margin: 0em 0 0 0.3em;
            font-size: 1.1em;
            color: #1D7AFF;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.9em;
        }
    }

    a#navCommunity {
        color: #909AFF;
        padding-top: 15%;
    }

    .avatar-name-kor{
        display: flex;
        align-items: center;

        span{
            margin: 0 2.5em 0 0;
            font-size: 0.96em;
        }

        .avatar-container{
            width: 2em;
            height: 2em;
            background-color: #1D7AFF;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 1.06em;
            margin: 0 0.5em 0 0;
        }
    }

    .icon-navbar{
        width: 2.5em;
        height: 2.5em;
        background-color: #F0F1F9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .last-item img{
            width: 1em;
            height: 1em;
        }

    }

    .last-item{
        margin: 0 0 0 0.5em;

        img{
            width: 1.2em;
            height: 1.2em;
        }
    }

    .documentation-link{
        img{
            width: 1.5em;
            height: 1.5em;
        }

        
        a{
            width: 1.5em;
            height: 1.5em;
        }
    }

    .community-link{
        img{
            width: 1.55em;
            height: 1.55em;
        }
    }

    .menu-responsive{
        display: none;
    }
}

$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-phone) {
    .menu-non-responsive{
        display: none !important;
    }

    .menu-responsive{
        display: flex !important;
        align-items: center !important;
        justify-content: center;

        img{
            width: 1.3em;
            height: 1.3em;
        }
    }

    .kor-navbar{
        padding: 1.5em;

        .kor-connect{
            width: 6.5em;
        }
    } 

    .avatar-name-kor{
        font-size: 0.9em;

        .avatar-container{
            width: 1.8em !important;
            height: 1.8em !important;
            margin: 0 1em 0 0 !important;
        }

        span{
            margin: 0 1em 0 0 !important;
            display: none;
        }
    }

    .icon-navbar{
        width: 1.9em !important;
        height: 1.9em !important;

        img{
            width: 1.5em;
            height: 1.5em;
        }
    }
}
