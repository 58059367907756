.modal-delete-account{
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    background-color: rgba(42, 49, 88, 0.5);
    top: 0;
    z-index: 100;
    align-items: center;
    justify-content: center;
    padding: 1em 3em 0 3em;

    .confirmation-modal{
        width: 30%;
        height: auto;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;

        img{
            width: 1.5em;
            height: 1.5em;
            padding: 1em;
        }

        p{
            margin: 0;
        }

        p:nth-child(2){
            font-weight: 600;
            font-size: 1.1em;
            margin: 2em 0 0.5em 0;
        }

        .close-icon{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 1em 1em 0 0em;
            cursor: pointer;
            opacity: 0.7;
        }

    }
    
    .content-modal-text-1{
        width: 85%;
        margin: 1em 0 0 0;
        
        p{
           text-align: left;
        } 
    }

    
    .button-delete-account{
        display: flex;
        width:95%;
        align-items: center;
        justify-content: center;
        margin: 2em 0 2em 0;

        button{
            text-transform: none;
            background-color: rgb(244, 67, 54) !important;
            font-weight: 400 !important;
            width: 43%;
            height: 3em;
            box-shadow: none;
        }

        button:nth-child(1){
            background-color: rgba(129,134,165,0.2) !important;
            color: #8186A5;
            margin: 0 1em 0 0;
            
        }

        .MuiButton-contained.Mui-disabled{
            background-color: rgb(229, 115, 115) !important;
        }

    }


    .title-modal-account{
        display: flex;
        align-items: center;
        width: 85%;
        font-size: 1.6em;

        p{
            margin: 0;
            font-weight: 600;
            text-align: left;
            
        }

        .title-icon-warning-1{
            width: 1.9em;
            height: 1.9em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0.5em 0 0;
            border-radius: 50%;
            background-color: rgba(240, 197, 71, 0.15);

            img{
                width: 100%;
                width: 1.3em;
                height: 1.3em;
            }
        }
    }

    button:hover{
        background-color: #8186A5; 
    }
}

.settings-page{
    display: flex;
    margin: 2em 0 2em 0;

    .menu-settings{
        width: 20%;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0 0 0 4em;
        border-right: 2px solid #f0f1f9;
        height: 75vh;

        .title-menu{
            font-weight: 600;
            font-size: 1.3em;
            margin: 1em 0 0.5em 0;
        }

        .section-name{
            border-bottom: 1px solid #f0f1f9;
            width: 80%;
            text-align: left;
            padding: .7em 0;
            margin: 0;
            cursor: pointer;
        }

        .active-tab{
            color: #1D7AFF;
            font-weight: 600;
            font-size: 1.05em;
        }
    }

    .content-settings{
        width: 80%;
        display: flex;
        text-align: left;
        margin: 0 4em 0 2em; 

        .delete-account-profile{
            width: 60%;
        }

        .copyright{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            text-align: right;
            width: 40%;
            color: #8186A5;
            font-size: 0.8em;
            padding: 0 0 3em 2em;
        }

        .title-api{
            font-size: 1.5em;
            font-weight: 600;
        }

        .title-api-2{
            font-size: 1.2em;
            font-weight: 600;
        }

        .card-user{
            border: 1px solid #f0f1f9; 
            display: flex;
            align-items: center;
            padding: 1em;

            .user-avatar{
                width: 3em;
                height: 3em;
                background-color: #1D7AFF;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 1.06em;
                margin: 0 0.5em 0 0;
                font-size: 1.1em;
                font-weight: 600;

                p{
                    font-size: 1.3em;
                }
            }

            .info-user{
                margin: 0 0 0 0.5em;

                p{
                    margin: 0;
                }

                p:nth-child(1){
                    font-size: 1.1em;
                    font-weight: 600;
                }

                span{
                    font-weight: 600;

                }
            }
        }
    }

    button{
        background-color: #8186A5;
        color: white;
        width: 90%;
        font-family: 'Work Sans';
        font-weight: 400;

        img{
            margin: 0 0.5em 0 0;
        }
    }
    
    button:hover{
        background-color: #8186A5; 
    }

    .button-documentation{
        text-transform: capitalize;
        width: 40%;

        a{
            color: white;
            text-decoration: none;
        }
    }

    .button-documentation-2{
        text-transform: lowercase;
        width: 40%;

        a{
            color: white;
            text-decoration: none;
        }
    }
}

.kor-title-create{
    span{
        cursor: pointer;
    }
}

.settings-api-connect{
    span:hover{
        color: #1D7AFF;
    }
}

$breakpoint-phone-mini: 360px;
$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-phone) {
    .settings-page{
        flex-direction: column;

        .kor-title-create{
            width: 85%;
        }

        .menu-settings{
            width: 85%;
            height: auto;
            flex-direction: row;
            align-items: center;
            margin: 0 1.5em;

            p{
                margin: 0 1em 0 0 !important;
                padding: 0 !important;
                border: none;
            }

            .section-name{
                border: none;
                width: auto;
            }
        }

        .content-settings{
            flex-direction: column;
            margin: 2em 1.5em;

            .delete-account-profile{
                width: 100%;
                
            }

            p{
                font-size: 0.9em;
            }

            .title-api{
                font-size: 1.1em;
            }

            button{
                width: 100% !important;
            }

            .copyright{
                width: 100%;
                margin: 3em 0 0 0;
            }
        }



    }


    .confirmation-modal{
        width: 100% !important;
        padding: 1em 0.5em 2em 0.5em;
        font-size: 0.9em;

        button{
            width: 90%;
        }
    }

    .modal-delete-account{
        position: fixed !important;

        .confirmation-modal{
            padding: 1em 0.5em 5em 0.5em;
        }
        
    }
}

@media (max-width: $breakpoint-phone-mini){
    .modal-delete-account{
        position: fixed !important;
    }
}

@media only screen and (min-width: 768px) {
       .confirmation-modal{
           width: 40% !important;
       }
}

@media only screen and (min-width: 1200px) {
    .confirmation-modal{
        width: 30% !important;
    }  
}
