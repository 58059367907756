.container-apis{
    display: flex;
    flex-direction: column;
    margin: 0em 4em 1em 4em;

    .container-item{
        width: 96%;
        display: flex;
        border: 1px solid #E6E8FF;
        padding: 1.5em 1.5em;
        border-radius: 16px;
        margin-bottom: 1em;
        justify-content: space-between;
        align-items: center;

        .icon-api-item{
            background-color: #1FC173;
            width: 3em;
            height: 3em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 1.2em 0 0;

            img{
                width: 2em;
            }
        }

        .name-info{
            width: 33%;
            display: flex;
            align-items: center;

            p{
                padding: 0;
                margin: 0;
            }

            .api-name-info{
                display: flex;
                flex-direction: column;
                text-align: left;

                .name-api{
                    font-size: 1.1em;
                    font-weight: 600;
                }

                .last-updated{
                    font-size: 0.8em;
                    text-transform: uppercase;
                    font-weight: 600;
                    margin: 0.3em 0;
                    
                    span{
                        color: #8186A5;
                        font-weight: 400;
                    }
                }

                .container-avatar-created-by{
                    display: flex;
                    font-size: 0.8em;
                    text-transform: uppercase;
                    font-weight: 600;
                    align-items: center;

                    .avatar-created-by{
                        display: flex;
                        font-weight: 400;
                        margin: 0 0.5em 0 0.5em;
                        background-color: #F0F1F9;
                        padding: 0.3em 1em 0.3em 0;
                        border-radius: 16px;
                        align-items: center;

                        p{
                            text-transform: capitalize;
                        }
                        
                        div:nth-child(1){
                           background-color: #1d7aff;
                           color: white;
                           width: 1.5em;
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           height: 1.5em; 
                           text-align: center;
                           border-radius: 50%;
                           margin: 0 0.3em 0 0;
                        }
                    }
                }
            }
        }



        .warning-ctn{
            img{
                width: 1.3em;
            }
        }

        .endpoints-info{
            width: 33%;
            display: flex;
            flex-direction: column;
            align-items: center;

            p{
                margin: 0;
                font-weight: 600;
                font-size: 0.8em;
                letter-spacing: 0.8px;
            }

            div{
              background-color: #F0F1F9;
              width: auto;
              padding: 0.5em 1em;
              border-radius: 16px;
              margin: 0 0 0.5em 0; 
              font-size: 0.9em 
            }
        }

        .remove-button{
            width: 25%;
            display: flex;
            justify-content: flex-end;
            margin-left: 0.5em;


            button{
                width: 12em;
                height: 3em;
                background-color: #9e9e9e !important;
                font-weight: bold;
                font-size: 0.9em;
                display: flex;
                align-items: center;
                box-shadow: none;
                color: white;
                text-transform: capitalize;
                font-weight: 400;
        
                img{
                    margin: 0 0.5em 0 0;
                }
            }
        
            button:hover{
                background-color: #5e5d5d !important;
            }
        }

        .view-details-button{
            width: 20%;
            display: flex;
            justify-content: flex-end;


            button:nth-child(1){
                width: 12em;
                height: 3em;
                background-color: #EA6F2E !important;
                font-weight: bold;
                font-size: 0.9em;
                display: flex;
                align-items: center;
                box-shadow: none;
                color: white;
                text-transform: capitalize;
                font-weight: 400;
        
                img{
                    margin: 0 0.5em 0 0;
                }
            }
        
            button:nth-child(1):hover{
                background-color: #EA6F2E !important;
            }

            button:nth-child(2){
                width: 12em;
                height: 3em;
                background-color: #8186A5 !important;
                font-weight: bold;
                font-size: 0.9em;
                display: flex;
                align-items: center;
                box-shadow: none;
                color: white;
                text-transform: capitalize;
                font-weight: 400;
                margin: 0 0 0 1em;
        
                img{
                    margin: 0 0.5em 0 0;
                }
            }
        
            button:nth-child(2):hover{
                background-color: #8186A5 !important;
            }
        }
    }
}
