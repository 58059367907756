.note-api-localhost{
    text-align: left;
    font-size: 0.92em;
    a{
        color: #1D7AFF;
        text-decoration: none;
    }

    span{
        font-weight: 600;
    }
}


.container-modal-kor{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: row-reverse;
    top:0; 
    left:0;

    .ctn-invisible {
        min-height: auto;
    }

    .alert-ctn {
        min-height: auto;
    }
    
    .white-text {
        color: white;
    }
    
    .white-text-absolute {
        color: white;
        position: absolute;
        top: 0;
        right: 7%;
    }

    .purple-text {
        color: #969FFF;
    }

    .purple-text-absolute {
        color: #969FFF;
        position: absolute;
        top: 0;
        right: 9%;
    }

    .background-opacity{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(42,49,88,.5);
        z-index: 100;
    }

    .title-allowed-domain{
        text-align: left;
        font-weight: 600;
        font-size: 1.05em;
    }

    .relative {
        position: relative;
        width: 22%;
        
        p{
            width: 100% !important;
        }
    }

    .opacity-0{
        opacity: 0;
    }

    .content-modal{
        background-color: #fff;
        width: 40%;
        height: 100%;
        padding: 2em 2em;
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 500;

        .title-modal{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        .content-modal-1{
            height: 60%;
            overflow-y: scroll;
            padding: 2em 0 0 0;

            .input-modal-add{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-size: 0.9em;
                cursor: pointer;
                color: #1d7aff;
            }

            .input-modal{
                display: flex;
                align-items: center;
                p{
                    margin: 0 1em 0 0;
                    font-size: 0.96em;
                    width: 22%;
                    text-align: left;
                }

                input{
                    width: 80%;
                    margin: 0.5em 0 0.5em 0;
                    border-radius: 4px;
                    border: none;
                    background-color: #F0F1F9;
                    height: 3em;
                    padding: 0 0 0 1em;
                    font-family: 'Work Sans', sans-serif;
                    font-size: 0.96em;
                }

                select{
                    width: 80%;
                    margin: 0.5em 0 0.5em 0;
                    border-radius: 4px;
                    border: none;
                    background-color: #F0F1F9;
                    height: 3em;
                    padding: 0 0 0 1em;
                    font-family: 'Work Sans', sans-serif;
                    font-size: 0.96em;
                }

                
                input:focus{
                     outline: none;
                }
            }
        }



        .content-modal-1::-webkit-scrollbar {
            display: none;
        }

        .content-modal-1{
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        

        .buttons-action{
            height: 10%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            
    button:nth-child(2){
        width: auto;
        height: 3em;
        background-color: #EA6F2E !important;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        box-shadow: none;

        img{
            margin: 0 0.5em 0 0;
        }
    }

    button:nth-child(2):hover{
        background-color: #EA6F2E !important;
    }

    button:nth-child(1){
        color: #8186A5;
        margin: 0 0.5em 0 0em;
        border: 1.5px solid #8186A5 !important;
        font-weight: 600;
        width: 10em;
        height: 3em;
        background-color: white !important;
        box-shadow: none;
    }


        }

        .close-modal{
            display: flex;
            width: 100%;

            img{
                cursor: pointer;
            }

            p{
                font-size: 1.5em;
                font-weight: 600;
                margin: 0 0 0 1em;

                span{
                    color: #1FC173;
                }
            }
        }
    }

    .allowed-modal{
        .pointer{
            p{
                display: none;
            }
        }
    }

    .modal-add-domain{
        height: 95%;
        overflow: scroll;
    }

    .modal-add-domain::-webkit-scrollbar {
        display: none;
    }

}

.input-modal{
    img{
        width: 0.8em;
    }
}

.modal-warning{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: row;
    top:0; 
    left:0;
    align-items: center;
    justify-content: center;

    
    .background-opacity{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
    }

    .content-modal-warning{
        background-color: #fff;
        margin-top: 0;
        width: 30%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        z-index: 200;
        border-radius: 20px;
        
        .button-got-it{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        button{
            width: 12em;
            height: 3em;
            background-color: #EA6F2E !important;
            font-weight: bold;
            font-size: 0.9em;
            box-shadow: none;
            color: white;
            text-transform: capitalize;
            font-weight: 600;
        }

        .undone-text{
            margin: -1em 0 0 0;
            text-align: left;
        }

        .button-delete-connection{
            display: flex;
            align-items: center;
            justify-content: center;

            div{
                width: 95%;
            }


            button{
                width: auto;
                text-transform: none;
                background-color: rgb(244, 67, 54) !important;
                font-weight: 400 !important;
                width: 43%;
            }

            button:nth-child(1){
                background-color: rgba(129,134,165,0.2) !important;
                color: #8186A5;
                margin: 0 1em 0 0;
            }
            
        }

        .close-icon-warning{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 1em 1em 0 1em;
            cursor: pointer;
            opacity: 0.7;
        }

        .content-warning{
            margin: 1em 3em 0 3em;

            .link-documentation{
                margin: 1em 0 1em 0;
                text-align: left;

                a{
                    text-decoration: none;
                    color: #1D7AFF;
                }

                span{
                    color: #1D7AFF;
                }
            }



            .title-modal-warning{
                display: flex;
                font-weight: 600;
                text-align: left;
                align-items: center;
                font-size: 1.6em;

                .icon-info-title{
                    width: 1.8em;
                    height: 1.8em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0.5em 0 0;
                    background-color: rgba(240, 197, 71, 0.15);
                    border-radius: 50%;

                    img{
                        width: 60%;
                    }
                }

                .info-icon-connection{
                    width: 1.8em;
                    height: 1.8em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0.5em 0 0;
                    background-color: rgba(190, 255, 224, 0.5);
                    color: white;
                    border-radius: 50%;

                    img{
                        width: 70%;
                    }
                }
            }

            .warning-icon{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
                

                span{
                    font-weight: 600;
                }
                

                p{
                    text-align: left;
                    width: 90%;
                    margin: 0;
                }

                img{
                    width: 10%;
                    width: 1.5em;
                    height: 1.5em;
                    margin: 0 0em 0 0em;
                }

                .icon-info-warning{

                    p{
                        width: 2em;
                        text-align: center;
                        font-weight: bold;
                    }
                    text-align: center;
                    display: flex;
                    width: 2em;
                    height: 2em;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(29, 122, 255, 0.1);
                    border-radius: 50%;
                    color: #1D7AFF;

                    img{
                        width: 1.4em;
                    }
                }
                
            }
        }

        .button-got-it{
            margin: 1em 0em 2em 0em; 
        }
    }
}

.menu-responsive-1{
    width: 100% !important;
    padding: 0em 0 0 0;
    justify-content: flex-start;

    .content-modal{
        padding: 2em 0 0 2em !important;
        width: 70% !important;


        .menu-sections{
            padding: 1em 0 0 0;

            .menu-section{
                p{
                    width: 90%;
                    padding: 1em 0;
                    border-bottom: 1px solid #f1f4f8;
                }

                a{
                    color: #2a3158;
                    text-decoration: none;
                }
            }

            .log-out-button{
                padding: 1em 0 2em 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                width: 90%;

                button{
                    width: 12em;
                    height: 3em;
                    background-color: #8186A5 !important;
                    font-size: 0.75em;
                    text-transform: capitalize;
                    font-weight: 600;

                    img{
                        width: 1.1em;
                        margin: 0 0.5em 0 0;
                    }

                }
            }
            
        }
    }

    .title-modal{
        .close-modal{
            p{
                font-size: 1.3em !important
            }
        }
    }

    .menu-sections{
        display: flex;
        flex-direction: column;
        text-align: left;

        p{
            width: 100%;
            margin: 0;
        }
    }
}

.mobile-tooltip-modal{
    display: none !important;
}

$breakpoint-phone: 600px;
$breakpoint-phone: 700px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;

@media (max-width: $breakpoint-tablet) {
    .MuiTooltip-popper{
        .MuiTooltip-tooltip{
            font-size: 0.6em  !important;
        }
        
    }

    .container-modal-kor{
        
        .mobile-tooltip-modal{
            display: flex !important;
        }

        .desktop-tooltip-modal{
            display: none;
        }
        
        .content-modal{
            width: 95%;
            padding: 2em 0 0 2em;
            position: fixed;
            z-index: 500;
        }

        .content-modal-warning {
            width: 85%;
        }

        .relative {
            width: 100%;
        }

        .content-modal-1{
            height: 70% !important;
            padding: 1em 0 0 0 !important;

            img{
                margin: 0.5em 0 0 0;
            }



            .input-modal{
                flex-direction: column;
                align-items: flex-start !important;
                text-align: left !important;
            }

            .input-modal p{
                font-size: 0.9em !important;
                width: 95% !important;
            }

            .input-modal input{
                width: 90% !important;
                margin: 0.5em 0 0 0 !important;
                font-size: 0.9em !important;
            }

            .input-modal select{
                width: 93% !important;
                margin: 0.5em 0 0 0 !important;
                font-size: 0.9em !important;
            }

            .input-modal button{
                margin: 0.4em 0;
                padding: 0;
            }

            .input-modal-add{
                width: 90%;
            }


        }

                    
        .buttons-action{
            align-items: flex-start !important;
            justify-content: center !important;
            margin: 1.5em 0 1em 0;
            height: auto !important;

            button{
                font-size: 0.78em !important;
            }
        }

        .close-modal p{
            font-size: 1.3em !important;

        }

        .caption-modal{
            text-align: left;
            font-size: 0.9em;
        }

        .allowed-modal{
            flex-direction: column;
            align-items: flex-start;


            .pointer{
                width: 90%;
                margin: 0 !important;
                padding: 0 !important;
                display: flex;
                align-items: center;

                p{
                    width: 100%;
                    display: flex !important;
                    margin: 0 0 0 0.5em;
                }
            }

            p{
                width: 100%;
            }



            input{
                width: 90%;
            }
        }

        
        .input-modal-add{
            margin: 0 1.5em 0 0;
        }

        .input-modal-add-button{
            margin: 0 1em 0 0;
        }


    } 
}


